<template>
    <modal-template
        @close="$emit('close')"
        :active="true"
        type="primary">
        <template slot="modal-header">
            <span class="title has-text-white">Edit Framework Metadata</span>
        </template>
        <template
            slot="modal-body">
            <label class="label is-large is-family-secondary heavy">Metadata Info</label>
            <code class="label">{{ framework.id }}</code>
            <label 
                class="label" 
                for="tags">
                Tags
            </label>
            <textarea 
                class="textarea import-text-textarea" 
                type="textarea" 
                name="tags" 
                ref="tags"
                placeholder="tag1,tag2,tag3" />
            <br>
            <input 
                name="clearImage"
                type="checkbox">
            <label for="clearImage">Remove Current Thumbnail</label>
            <br>
            <div class="container">
                <label 
                    class="label" 
                    for="thumbnailPreview">
                    Thumbnail
                </label>
                <input
                    type="file"
                    ref="imageInput"
                    id="imageInput"
                    name="image"
                    accept="image/*"
                    required>
                <img
                    ref="thumbnailPreview">
            </div>
        </template>
        <template slot="modal-foot">
            <div class="buttons is-pulled-right">
                <button
                    v-if="isAdmin"
                    type="submit"
                    class="button is-danger is-outlined"
                    :disabled="submissionButtonsBusy"
                    @click="setAsSpotlight">
                    <span class="icon">
                        <i class="fa fa-star" />
                    </span>
                    <span>Spotlight This</span>
                </button>
            </div>
            <div style="width: 10px"></div>
            <div class="buttons is-pulled-right">
                <button
                    type="submit"
                    class="button is-primary is-outlined"
                    :disabled="submissionButtonsBusy"
                    @click="updateMetadata">
                    <span class="icon">
                        <i class="fa fa-pen-nib" />
                    </span>
                    <span>Update Metadata</span>
                </button>
            </div>
            <div style="width: 10px"></div>
            <div
                class="buttons is-spaced">
                <button
                    class="button is-secondary is-outlined"
                    @click="$store.commit('app/closeModal')">
                    <span class="icon">
                        <i class="fa fa-times-circle" />
                    </span>
                    <span>Close</span>
                </button>
            </div>
        </template>
    </modal-template>
</template>

<script>
import ModalTemplate from './ModalTemplate.vue';
import {cassApi} from '../../mixins/cassApi';
import {cassUtil} from '../../mixins/cassUtil';
export default {
    name: 'UpdateMetadata',
    mixins: [cassApi],
    props: {
        isActive: Boolean
    },
    components: {
        ModalTemplate
    },
    data: () => {   
        return {
            metadataInFlight: false
        };
    },  
    asyncComputed: {
        getThumbnailSrc: async function() {
            alert("getting thumbnail src....");
            let metadata = await cassUtil.methods.getMetadata(this.framework.id);
            if (metadata !== undefined && metadata !== null)
                return metadata.thumbnailSrc;
            else
                return undefined;
        }
    },
    computed: {
        userIsAdmin: function() {
            return this.isAdmin();
        },
        submissionButtonsBusy: function() {
            return this.metadataInFlight;
        },
        framework: function() {
            if (this.objFromListItemInfo && (this.objFromListItemInfo.type === "Framework" || this.objFromListItemInfo.type === "ConceptScheme")) {
                return this.objFromListItemInfo;
            }
            return this.$store.state.editor.framework;
        },
        spotlightPostEndpoint: function() {
            let apiEndpoint = window.repo.selectedServer;
            if (typeof apiEndpoint !== "string") {
                console.error(">> Cannot find /api/metadata endpoint");
                return undefined;
            }
            if (!apiEndpoint.endsWith("/")) {
                apiEndpoint += "/";
            }
            return apiEndpoint + "spotlight";
        }
    },
    methods: {
        isAdmin: () => cassUtil.methods.isAdmin(),
        close: function() {
            this.$store.commit('app/closeModal');
        },
        updateMetadata: async function() {
            this.metadataInFlight = true;
            let data = new FormData();
            let fileInput = this.$refs.imageInput;
            let thumbnail = null;
            if (fileInput.files !== undefined && fileInput.files.length > 0) {
                thumbnail = fileInput.files[0];
                data.append('image', thumbnail);
            }
            data.append('uri', this.framework.id);
            data.append('tags', this.$refs.tags.value);
            let endpoint = cassUtil.methods.postMetadataEndpoint(this.framework.id);
            let sendRes = await fetch(endpoint, {
                method: "POST",
                body: data
            });
            alert(sendRes.status);
            this.metadataInFlight = false;
        },
        setAsSpotlight: async function() {
            this.metadataInFlight = true;
            let endpoint = this.spotlightPostEndpoint;
            alert("Framework: " + JSON.stringify(this.framework));
            alert("Framework ID: " + this.framework.id);
            let sendRes = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    uri: this.framework.id
                })
            });
            alert(sendRes.status);
            this.metadataInFlight = false;
        }
    },
    mounted() {
        // alert("mounted! " + this.metadataGetEndpoint);
        let endpoint = cassUtil.methods.getMetadataEndpoint(this.framework.id);
        fetch(endpoint)
            .then(getRes => getRes.json())
            .then(body => {
                if (body.data != null) {
                    alert("setting image!");
                    let imgData = `data:${body.contentType};base64, ${body.data}`;
                    this.$refs.thumbnailPreview.src = imgData;
                }
                this.$refs.tags.value = body.tags;
            });
    }
};
</script>
